.status-pickup {
  background-color: #00ff00 !important;
}

.status-pending {
  background-color: #ffff00 !important;
}

.status-delivered {
  background-color: #008000 !important;
}

.status-canceled {
  background-color: #ff0000 !important;
}

.status-delayed {
  background-color: #ffa500 !important;
}

.status-cancel-paid {
  background-color: #32beac !important;
}

.status-cancel-paid-delivery {
  background-color: #df47ec !important;
}

.index-list {
  display: flex;
  gap: 5px;
  font-size: 9px;
  margin: auto;
  /* position: absolute; */
  /* right: 45px; */
  /* top: 180px; */
}

.index-list li {
  display: flex;
  align-items: center;
}

/* @media screen and (max-width: 1244px) {
  .index-list {
    position: static;
    right: 45px;
}
} */
