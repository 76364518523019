.flaticon-archive {
  position: absolute;
  top: 1rem;
  right: 3rem;
  color: rgba(255, 255, 255, 0.4);
  font-size: 4rem;
}
.flaticon-archive:before {
  content: "\f128";
}
