h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3a3952;
  font-family: "Oswald", sans-serif;
}
ul {
  list-style: none;
  padding: 0;
  font-size:  1.3rem;
}

h2 {
  margin: 0;
}

h5 {
  font-weight: 500;
}

p {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #3a3952;
}

.invoice-address h3 {
  font-size: 25px;
  margin-bottom: 0.25rem;
}

.invoice-address h5 {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}


.text-right {
  text-align: right !important;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
table {
  border-collapse: collapse;
}

.thead-primary thead {
  background-color: #ea6e27;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.table thead tr th {
  border-top: 0;
}
.table thead th {
  border-bottom: 1px solid #dee2e6;
}
.thead-dark thead th,
.thead-primary thead th,
.thead-secondary thead th,
.thead-success thead th,
.thead-info thead th,
.thead-warning thead th,
.thead-danger thead th {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.w-5 {
  width: 5%;
}

th {
  text-align: inherit;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}
