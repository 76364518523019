.login {
  text-align: center;
  background-color: #132C33;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.login_container {
  background-color: #D8E3E7;
  min-width: 80%;
  min-height: 50vh;
  border-radius: 5%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .login_container {
    width: 30%;
    min-width: 600px
  }
}
