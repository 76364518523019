.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.highlight-editable {
  background-color: #268968; /* Set the highlight color as per your design */
  color: #2e1e1e;
}

.grid-container {
  display: grid;
  gap: 10px;
  /* background-color: #2196F3; */
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px;
  font-size: 30px;
}

.item1 {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.item2 {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.css-1uvydh2{ 
  height:5px !important
}

.active {
  color : #ffc107;;
}